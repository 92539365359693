import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-Motion Motion Detection Sensors",
  "path": "/Products/IN-Motion/500/Beachtungen/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Der IN-Motion 500 verfügt über eine Mikrowelle und einen passiven Infrarot-Bewegungsmelder. Es ist perfekt für die Outdoor-Überwachung geeignet.",
  "image": "../../P_SearchThumb_MotionDetector.png",
  "social": "/images/Search/P_SearchThumb_MotionDetector.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Motion_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-Motion 500 Motion Detection Sensors' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-Motion 500 comes with a microwave and a passive infrared motion sensor. It is the perfectly suited for outdoor detection.' image='/images/Search/P_SearchThumb_MotionDetector.png' twitter='/images/Search/P_SearchThumb_MotionDetector.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-Motion/500/Considerations/' locationFR='/fr/Products/IN-Motion/500/Considerations/' crumbLabel="Consideration" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-motion-500",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-motion-500",
        "aria-label": "in motion 500 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Motion 500`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Diskutieren Sie diesen Artikel im `}<a parentName="p" {...{
          "href": "https://forum.instar.de/c/andere-produkte/7"
        }}>{`INSTAR Forum`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "installationshinweise",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installationshinweise",
        "aria-label": "installationshinweise permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installationshinweise`}</h2>
    <h3 {...{
      "id": "was-muss-ich-vor-der-installation-des-pir-sensors-beachten",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#was-muss-ich-vor-der-installation-des-pir-sensors-beachten",
        "aria-label": "was muss ich vor der installation des pir sensors beachten permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Was muss ich vor der Installation des PIR-Sensors beachten?`}</h3>
    <p>{`Es wird empfohlen, den Sensor in einer Höhe zwischen 1,8 m und 2,4 m zu installieren, um eine optimale Abdeckung des Bereichs zu gewährleisten.`}</p>
    <p>{`Der Sensorabdeckungsbereich hat eine annähernd konische Form und ist durch eine Fresnel-Linse in Zonen unterteilt - siehe unten für einen Überblick über den Abdeckungsbereich. Das zu detektierende Ziel muss entweder in eine dieser Zonen oder aus einer Zone herausfahren, um erkannt zu werden. Kleine Ziele können sich manchmal zwischen diesen Zonen bewegen und werden nicht erkannt, da sie niemals in eine Zone gelangen oder diese verlassen.`}</p>
    <h4 {...{
      "id": "detection-window",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#detection-window",
        "aria-label": "detection window permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Detection Window`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "891px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6ca191b723c7f5dc520f022404a34519/b7877/IN-Motion500_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.78260869565219%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADoklEQVQ4y22TaW/iZhSF84unUv/ISFU7mkmajd2EnbCGSSDsiQ022AbCYlYDNsb7ixfKWwHph1Z9Pl7p6B6dc+8FPCEIGwCALMvidivLsiRJ6/VqtVxqmiZJkiBsNE3luONMFLer5XK1Wg36/QvTNAEA6/WK49ayLHMcJwiCLMtLlh0OB4N+v4GhlVLpo9djmFGv25nPZoIgbHhe3G4v7BOqqiiKAiE0DAPsAISQZdkGhgb8yP3NdTad6nTofv+DJFsjZihJkq7rEMKLs23btk3TghBalmXb9m4HcLzpdTsjwSCGoqPRkGUXY2b0/lbPZTOJeEzYbI5i0zB0Xd9sNjzHWZalqup2u0XR95ury+DDA01T/f5Hs4HV67V04tFxe4t4PD63q4FhR/FfJwAAZ9uWaeZfnq+vfrzmX5jxqNulfz5lPS6n8+7W7/XGwqFiIY9h6Hw2+49tE0JYrZTdjnuaIlsEHo9GfG6X1+VKJR7f6jWapiiKnC/mDDNi2cVRfGxIkhRZNk3z/a1+c3WZy2ZjkbDj7i4cCtZr1RaBU2SbYUaDwQeBNwf9D5qmptPJp+39fm9Z1s/c029fvyIeT8CPlIqvGIY2GxhJtt/f6uVSsV6rVkql51yuWChk02mapo7iHQC6ri1Z9tdfvnz/9nso8JCIxx5j0QefD/F6EK/H53b7vd7ggz/gRxCvJ+D3e1xOvNE4ivf7vXmCwPHv3/5wOx3RcKj4WsDQ4+ZOh8abjWq5VK9WysViLpt9zeczqSRFkaeqTFNVVVmSdrsdMxpdX10mE4+ZVNLrdsVj0Wql0sBQimzP5rPpbELT1GQyHg4HS5b9V9qGYUAIe93O3c11rVJuEXg6lYxFwvFoNJVMvObz7291gmhOp5PxmPlM27bt/d7WdV1VlMPhYFkWjjdvr/9MxGO9XrfVItLJRCwcyqRT6VQyHAhkUsnCy/NkzBzFhmFomqYqiiAIxwtTVFEUOzTlur9zOx0EgXe6dK1aLrw8R4KBeDRSLhVTyQRFkv9zJNY/dLt0APEFEKRaKTOj4WIxp2nqKZOOhsMYigIAPgODEGqaJgjCDpx607TD4bBaLgm8GQ2H3A5HOpnodjvD4YAk2+12azGfg/NX7fd7CKFpmqIonn9N13UAgKaqkiTxPDedTqaTCbdeb7dbnuc0TTMMY3fefLatnVAUxbZtCOGG53mOs23bsixRFHfGzrZtYbNRFBkeDpIojscMz/N/A2urzBVYdOufAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ca191b723c7f5dc520f022404a34519/e4706/IN-Motion500_16.avif 230w", "/en/static/6ca191b723c7f5dc520f022404a34519/d1af7/IN-Motion500_16.avif 460w", "/en/static/6ca191b723c7f5dc520f022404a34519/8c2dc/IN-Motion500_16.avif 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6ca191b723c7f5dc520f022404a34519/a0b58/IN-Motion500_16.webp 230w", "/en/static/6ca191b723c7f5dc520f022404a34519/bc10c/IN-Motion500_16.webp 460w", "/en/static/6ca191b723c7f5dc520f022404a34519/3b50c/IN-Motion500_16.webp 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ca191b723c7f5dc520f022404a34519/81c8e/IN-Motion500_16.png 230w", "/en/static/6ca191b723c7f5dc520f022404a34519/08a84/IN-Motion500_16.png 460w", "/en/static/6ca191b723c7f5dc520f022404a34519/b7877/IN-Motion500_16.png 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6ca191b723c7f5dc520f022404a34519/b7877/IN-Motion500_16.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Draufsicht Diagramm`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "891px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/340ee9dd733ec8874dc1db05cc0af193/b7877/IN-Motion500_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.78260869565219%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACCUlEQVQ4y9VUWVMaQRDeX55K+Q+MpvKWVB4ihyLXyr0sKCoqshzuEgVkOR+gWFbu+0t1l5BUyspDihe7qnd6enq+/aa7ZwTsWIT3Abher3emAn3+BP3b3szfsv/JcCPL5XI7XywWmM/nbK9WK57T0samkeJJZ7MZ+wRyDgYD1GpVNBoN6LqOSqWCer3G49PjI/RKBdVqFcXiE/v0V//zc5njS6Uir02nUwjj0QjtdhsZJY18LouCpiKTUZDP5zhIUx+gqg8ol0vQNJWVANRXP8Xkcln2M8PRaIQX08TnT/vY+/gBxzYrbJYjuJ2nED0euE4dCPp9iMdkSOEw5KiEq8sEEhfnSF5fIZfNQEnfMxlKjzCZTNDv9xEJB/Hl8ADfv31lwDOvB1EpArfTCevRDzgdJ3Ac2/knPtELj8vFay6Hg+P9osh53PZhKnUHu83CQHarBaLHDSkcwnk8htubJBQljcvEBXyiiDOvl0FDAT9ichSyJDHjLcNBv895OTzYR+rulpnQxkgoxBuDAT/isox0+h6FgoafhQIfOxQIMPiJ3Yab5DVXm/uQqBqGgVarhW63i16vh06nzcWiylMXNOp1riilp2cY6HQ6GA4HME0TVV1Hs9n83YfUOgS66amNjMdjVhI6Dp2GZDadYjgc8r7Vcsk2kWDAtzr/f28KX71d3uV39HztEvAX64OajhCQ+bwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/340ee9dd733ec8874dc1db05cc0af193/e4706/IN-Motion500_17.avif 230w", "/en/static/340ee9dd733ec8874dc1db05cc0af193/d1af7/IN-Motion500_17.avif 460w", "/en/static/340ee9dd733ec8874dc1db05cc0af193/8c2dc/IN-Motion500_17.avif 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/340ee9dd733ec8874dc1db05cc0af193/a0b58/IN-Motion500_17.webp 230w", "/en/static/340ee9dd733ec8874dc1db05cc0af193/bc10c/IN-Motion500_17.webp 460w", "/en/static/340ee9dd733ec8874dc1db05cc0af193/3b50c/IN-Motion500_17.webp 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/340ee9dd733ec8874dc1db05cc0af193/81c8e/IN-Motion500_17.png 230w", "/en/static/340ee9dd733ec8874dc1db05cc0af193/08a84/IN-Motion500_17.png 460w", "/en/static/340ee9dd733ec8874dc1db05cc0af193/b7877/IN-Motion500_17.png 891w"],
              "sizes": "(max-width: 891px) 100vw, 891px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/340ee9dd733ec8874dc1db05cc0af193/b7877/IN-Motion500_17.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Seitenansicht Diagramm`}</em></p>
    <p>{`Ziele, die sich direkt auf den Sensor zu oder von ihm weg bewegen, entgehen manchmal der Erkennung, da PIR-Sensoren für diese Art von Bewegung weniger empfindlich sind. Dies ist am wahrscheinlichsten bei kleinen Zielen.`}</p>
    <p>{`Bitte beachten Sie auch, dass Sie den PIR nicht auf einen Bereich richten sollten, in dem sich ständig bewegte Objekte wie Fahrzeuge, kleine Tiere, hängende Objekte oder Bäume befinden, die sich im Wind bewegen können.`}</p>
    <p>{`Sofern dies für die besten Ergebnisse nicht erforderlich ist, sollte sich ein Ziel im Abdeckungsbereich bewegen. Beachten Sie, wie die Zonen am Ende des Sensorbereichs weiter auseinander liegen und an den Rändern des Abdeckungsbereichs kürzer sind. Am Ende des Erfassungsbereichs deckt der Sensor die größte Anzahl von Quadratfuß ab. Je näher Sie sich dem Sensor nähern, desto geringer ist der Abdeckungsbereich.`}</p>
    <h3 {...{
      "id": "allgemeine-überlegungen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#allgemeine-%C3%BCberlegungen",
        "aria-label": "allgemeine überlegungen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Allgemeine Überlegungen`}</h3>
    <p>{`Temperaturen können sich in relativ kurzen Zeiten oft dramatisch ändern. Große Temperaturänderungen können den PIR-Sensor entweder empfindlicher oder unempfindlicher machen. Wenn die Umgebungstemperatur auf fast 37 ° C ansteigt, nimmt die Differenz zwischen Ziel- und Umgebungstemperatur ab - und die Empfindlichkeit des Sensors sinkt. Bei sinkenden Temperaturen gilt das Gegenteil und der Sensor wird empfindlicher.`}</p>
    <p>{`Wenn Ziel- und Umgebungstemperaturen gleich werden, kann der PIR-Sensor ein Ziel möglicherweise nicht mehr erkennen. Große Ziele weisen normalerweise eine ausreichende Variation ihrer Oberflächentemperatur auf, damit der Sensor sie erkennen kann - selbst wenn die Umgebungstemperatur der durchschnittlichen Körpertemperatur entspricht.`}</p>
    <p>{`Die IN-Motion 500 PIR-Sensoren kompensieren die Empfindlichkeit des PIR-Detektors in Abhängigkeit von der Umgebungstemperatur. Es wird jedoch empfohlen, den Mikrowellensensor an heißen Sommertagen (oder heißen Innenumgebungen) vom PIR zu trennen, um nicht nur von der Wärmeerkennung abhängig zu sein! Im entkoppelten Zustand (`}<a parentName="p" {...{
        "href": "/en/Products/IN-Motion/500/Setup/"
      }}>{`Der DIP-Schalter 5 muss sich in der Position OFF befinden`}</a>{`) beide Sensoren können einen Alarm auslösen.`}</p>
    <h3 {...{
      "id": "wie-minimiere-ich-den-fehlalarm-durch-den-pir-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wie-minimiere-ich-den-fehlalarm-durch-den-pir-sensor",
        "aria-label": "wie minimiere ich den fehlalarm durch den pir sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wie minimiere ich den Fehlalarm durch den PIR-Sensor?`}</h3>
    <p>{`Der größte Nachteil des PIR-Sensors besteht darin, dass er alles erkennt, was sich bewegt - heiße Luft, kühle Schatten unter vorbeiziehenden Wolken, aufsteigende Wärme, die in der umgebenden Vegetation gespeichert ist usw. - und eine von der Umgebungstemperatur abweichende Temperatur hat. Der IN-Motion 500 wurde optimiert, um diese Probleme zu minimieren. In Umgebungen mit rauen Umgebungsbedingungen sind einige falsche Anzeigen jedoch nicht zu vermeiden. Im Folgenden finden Sie einige Tipps, um falsche Ereignisse zu minimieren.`}</p>
    <p><strong parentName="p">{`Richtung`}</strong>{`
Richten Sie den Sensor von der aufgehenden oder untergehenden Sonne aus. Im Allgemeinen funktioniert der Norden oder Süden gut, aber die örtlichen Gegebenheiten können etwas anderes bestimmen.`}</p>
    <p><strong parentName="p">{`Warme Oberflächen`}</strong>{`
Richten Sie den Sensor auf einen Bereich, in dem kein intensives direktes Sonnenlicht herrscht, das den gesamten oder einen Teil des Erfassungsbereichs erwärmt. Schatten von Bäumen oder Wolken, die sich in einem von der Sonne aufgewärmten Bereich bewegen, können zu einem kurzzeitigen Temperaturabfall führen, der dazu führen kann, dass ein falsches Ereignis aufgezeichnet wird. Erwärmte Luft, die aus dem Boden steigt, kann ebenfalls Probleme verursachen.`}</p>
    <p><strong parentName="p">{`Vegetation`}</strong>{`
Hohe, sonnenerwärmte Gräser oder andere in einer Brise wehende Vegetation können erkannt werden. Richten Sie den Sensor von dichter, sonnenerwärmter Vegetation aus, die Wärme einfangen kann.`}</p>
    <p><strong parentName="p">{`Temperaturabfall`}</strong>{`
Halten Sie den Sensor auch in einem schattigen Bereich von dichten Büschen oder Bäumen fern, die die Wärme des Tages aufrechterhalten können. Wenn die Lufttemperatur nachts sinkt - und der noch warme Strauch sich im Wind bewegt, könnte diese Bewegung erkannt werden.`}</p>
    <p><strong parentName="p">{`Vögel`}</strong>{`
Wenn bekannt ist, dass in dieser Gegend viele kleine Vögel / Säugetiere leben, werden Sie sicherlich viele Rohlinge erhalten, da diese aktiven, schnellen Tiere häufig den Rahmen verlassen, bevor ein Bild aufgenommen werden kann.`}</p>
    <p><strong parentName="p">{`Wind`}</strong>{`
Wind kann falsche Ereignisse verursachen. Die sich bewegende Luft ist wärmer oder kühler als der Hintergrund. Platzieren Sie den Sensor in einem Bereich, der vor starken Winden geschützt ist.`}</p>
    <p><strong parentName="p">{`Installation`}</strong>{`
Wind kann auch dazu führen, dass sich das Objekt, an dem Ihr Sensor montiert ist, bewegt. Stellen Sie sicher, dass der Sensor an einem Objekt befestigt ist, das bei starkem Wind nicht schwankt. Jede Bewegung des Geräts kann vom Sensor als Bewegung interpretiert werden.`}</p>
    <p><strong parentName="p">{`Zielgröße`}</strong>{`
Ein kleines Ziel nahe dem Ende des Sensorbereichs durchquert nur einen winzigen Prozentsatz der gesamten abgedeckten Fläche und könnte der Erkennung entgehen. Ein kleines Ziel in der Nähe des Sensors durchquert einen größeren Prozentsatz der abgedeckten Fläche und ist viel einfacher zu erkennen. Die Größe eines Ziels, der Abstand zum Sensor und die Oberflächentemperatur spielen eine wichtige Rolle im Wirkungsbereich des Sensors.`}</p>
    <h3 {...{
      "id": "wie-zu-testen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wie-zu-testen",
        "aria-label": "wie zu testen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wie zu testen?`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "492px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7dd1c3e779bcf7d3f1e250229a545097/5c6e9/IN-Motion500_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "176.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAjCAIAAAAblL1PAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE0klEQVRIx4WVW3MSZxjHSb+FvffSDxA/Qzu9dapTM960WmY6HXPTeJVMa+3BUZJojLEaHVNJgIVdDsuyy5457IGTIbCQQJZT4gE2kkICcng7BAdjDMl7t7PP7zm9z/N/dWD46Xa7nU5H0yqxaLTdbnc7nSMGumFk58A0GgnPThu8uIf0EgfuOqfD3W4XALBVKsE264zhrigIicSaKAQHTofCfXK3WoVtVoLAAQAMRTEUZYfhnKoODI6B+z9arZYbRTE3ur+/3+l0QrI0/vNPFEm6UWez2RwauQ8zNO2wI81mo1b7D3OjCGIjcA9NkumUIktSz+wged3nZEiWYZu11Wq9ef0aspgxDN3e2jrwSKVTKcKDtVqto5H7nVhfTyOwbW9/L51KrSwbQyG5n2e9VsNQ9M7tvx0IXMjn+5F0h2Nub2877MjOzo4QDFohy6tX2wCAarUqBANWqwW2WR/cvxeNhP0+Xz+YbkBWq1Xcg6nqJu7BAv7e73fv3nEsYzGbCNyjJJOdTodjGJZhMNT1AR60l2MYURRomtzMZivlMs+xTgciSeJWqfT2zZtsNhMIBB7/s7Aw/+DW7zcfPpzv1dyHgwE/7nGLorC+npZlCfdgkigkEwlRFAgChyALBFkYhuZ57sbEL+fPjy4tLeXz+V7aqy9fWkwrHMcyNOVyOAgC9/G9Dx/Ph0NyOpXSKpVmswEAWIvHx69fP3v27NTUVDQa1amqOmO4+2zxCWQ2uV1OH8/5fb5wKJRSFCWZVBKJWDQSCPhpivRgGGyzfvP1V1+eOWM0vuilrb/6/dilb2cMd54/e+qwIwTu8fG8j+d5rudFFARZliLhcCwWjYTD2Wzm8uXvvhgZiYTDPXgtHv/j5q9PnzxGbFYEtqEuJ0V6KZLkWMbHcRzLBgP++OrL9XS6WCwU8vkrV65cuHBhYWHhw5AUi0UEtlFeL8exsiRSpJdjGVkW1+JxJZmIxaIUScI269Lz55cuXvzx6g/zc/dvTEx87HZOVb24JxIOsTSdUpRsNuv38S6ng6bItbV4qVgEABBe79zcfTfqNL5YmpqcLJVKH4cks7FBeol8LkeTJMvQOztavV5XFAV1uayQBYZtsixJouAlcFmSFCXZarU/Gc/MxobL6djd3U2nUxazye/z1Wo1AICmadFohCS9szPT96anSYJ4//79MYuxmc1CkFnTKvuNBkNTJtOKLEv1er1v40BgN4pyLPNxtg+tZI/P5dRl4wtV3QQA5PM5hx2BLOZIJFyplIOBQCQcSiYSn2zVkZXOqarR+G98dRUA0Gg0QiHJjsAP5x9AFjPPsuW3b4+HB7yqqsvLRkkUugdH07Tbf/1ph208xw1qPEk9VVU1rSwzDN1ut3M5lWMYP8/3r61voDtB8Xv9z2QsFpPXi5tWlhPxOE2Sh9X3JNFvt9sAgEIhf+vmb5LQu2FN006S3iMCPDk5WSgUNjezOIbFYtGBbp4i+qqqXrt2bWxsrFAoKIoSCAQOxzwFRlFUr9efO3eO5/lqtXrkoTkFTqVSo6OjBoNh8Fx+bqkb9j5mMpmRkZHZ2dl+8ce2Zmjkubm58fFxvV5fLpc/r/akbptMpsXFRQDAo0ePYrHYsQUfDzcajcXFxb29vZ4kB4MQBA2L/D+r5iA+rMwg8AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dd1c3e779bcf7d3f1e250229a545097/e4706/IN-Motion500_22.avif 230w", "/en/static/7dd1c3e779bcf7d3f1e250229a545097/d1af7/IN-Motion500_22.avif 460w", "/en/static/7dd1c3e779bcf7d3f1e250229a545097/2b06e/IN-Motion500_22.avif 492w"],
              "sizes": "(max-width: 492px) 100vw, 492px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7dd1c3e779bcf7d3f1e250229a545097/a0b58/IN-Motion500_22.webp 230w", "/en/static/7dd1c3e779bcf7d3f1e250229a545097/bc10c/IN-Motion500_22.webp 460w", "/en/static/7dd1c3e779bcf7d3f1e250229a545097/9d6da/IN-Motion500_22.webp 492w"],
              "sizes": "(max-width: 492px) 100vw, 492px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dd1c3e779bcf7d3f1e250229a545097/81c8e/IN-Motion500_22.png 230w", "/en/static/7dd1c3e779bcf7d3f1e250229a545097/08a84/IN-Motion500_22.png 460w", "/en/static/7dd1c3e779bcf7d3f1e250229a545097/5c6e9/IN-Motion500_22.png 492w"],
              "sizes": "(max-width: 492px) 100vw, 492px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7dd1c3e779bcf7d3f1e250229a545097/5c6e9/IN-Motion500_22.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Führen Sie nach dem Anbringen des Geräts an einer Wand einen Gehtest durch, um sicherzustellen, dass das Gerät funktioniert. Bitte vor dem Test prüfen:`}</p>
    <ul>
      <li parentName="ul">{`DIP1- und DIP5-Schalter sind auf "Ein" geschaltet`}</li>
      <li parentName="ul">{`Stellen Sie sicher, dass alle Kabel korrekt sind`}</li>
    </ul>
    <p><strong parentName="p">{`Schritt 1.`}</strong>{`  Wenn das Gerät eingeschaltet ist, warten Sie bitte 15 Minuten, bis das Gerät die Selbstinspektion abgeschlossen hat (die rote LED hört auf zu blinken, sobald der Vorgang abgeschlossen ist) und warten, bis sich die Sensoren aufwärmen. Wenn Sie sich innerhalb des Erfassungsbereichs bewegen, leuchtet die rote LED auf und signalisiert eine Erkennung, wenn Sie von beiden Sensoren erkannt wurden. Nach 2 Sekunden ohne Bewegung erlischt die LED. Warten Sie 15s, bis die Sensoren wieder aktiv werden.`}</p>
    <p><strong parentName="p">{`Schritt 2.`}</strong>{`  Gehen Sie nun zum Rand des Erfassungsbereichs und überprüfen Sie den LED-Status. Die blaue LED leuchtet, wenn Sie sich im PIR-Erkennungsbereich befinden, und blinkt, wenn Sie sich im Mikrowellenerkennungsbereich befinden.`}</p>
    <p><strong parentName="p">{`Schritt 3.`}</strong>{`  Wiederholen Sie Schritt 2 an allen Rändern des Erkennungsbereichs, um zu sehen, ob er den erforderlichen Bereich abdeckt. Die rote LED muss aufleuchten, um Ihnen mitzuteilen, dass der PIR- und der Mikrowellensensor Sie erkannt haben.`}</p>
    <p><strong parentName="p">{`Schritt 4.`}</strong>{`  Sie können die Mikrowellenstärke im Uhrzeigersinn einstellen, um die Empfindlichkeit zu verstärken - falls erforderlich.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      